<template>
  <i
    :data-feather="icon"
    :width="width"
    :height="height"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
  >
  </i>
</template>

<script>
import feather from 'feather-icons'

export default {
  props: {
    icon: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      default: 24
    },
    height: {
      type: Number,
      default: 24
    }
  },
  mounted() {
    feather.replace()
  }
}
</script>

<style lang="scss" scoped></style>
