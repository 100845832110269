import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import './assets/main.css'
import './assets/typography.less'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import VirtualScroller from 'vue-virtual-scroller'
import { createHead } from '@unhead/vue'
import * as Sentry from '@sentry/vue'

import App from './App.vue'
import router from './router'

const app = createApp(App)
const head = createHead()

if (import.meta.env.PROD) {
  Sentry.init({
    app,
    release: '',
    dsn: 'https://be42d6032e9203f0eadf6273258d4ca6@o4507445113192448.ingest.us.sentry.io/4507445149630464',
    environment: 'production',
    integrations: []
  })
}

app.use(createPinia())
app.use(router)
app.use(VirtualScroller)
app.use(head)

app.mount('#app')
