import * as Sentry from '@sentry/vue'

const shouldLog = () => {
  return !import.meta.env.PROD || localStorage.getItem('mud:debug')
}

export function error(err) {
  Sentry.captureException(err)

  if (shouldLog()) {
    console.error('mud:', err)
  }
}

export function debug(...args) {
  if (shouldLog()) {
    console.log('mud:', ...args)
  }
}
