<template>
  <div class="app-input" :class="{ hasError }">
    <input
      :class="{ hasError }"
      ref="input"
      :type="type"
      :placeholder="placeholder"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <div v-if="hasError" class="subtext">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppInput',
  props: {
    modelValue: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue'],
  computed: {
    hasError() {
      return !!this.error
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    }
  }
}
</script>

<style lang="less" scoped>
.app-input {
  position: relative;

  input {
    border: solid 2px var(--border-color);
    border-radius: 4px;
    background: var(--bg-color);
    width: 100%;
    padding: 4px;

    &:focus,
    &:focus-visible {
      border-color: var(--focus-border-color);
      outline: none;
    }

    &:active {
      border-color: var(--active-border-color);
    }
  }

  .subtext {
    font-size: var(--font-size-xs);
    color: var(--input-text-secondary-color);
    position: absolute;
    bottom: -4px;
  }

  &.hasError {
    input {
      &:not(:focus),
      &:not(:focus-visible) {
        border-color: var(--error-color);
      }
    }

    .subtext {
      color: var(--error-color);
    }
  }
}
</style>
