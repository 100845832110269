<template>
  <div class="terminal-view">
    <div v-if="!isConnected" class="terminal-view__init">
      <server-select @submit="handleSubmit" />
    </div>
    <message-list v-if="isConnected" class="terminal-view__messages" :messages="messages" />
    <div v-if="isConnected" class="terminal-view__controls">
      <input
        ref="prompt"
        type="text"
        name="prompt"
        id=""
        v-model="prompt"
        @keyup.enter="handleEnter"
      />
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue'
import uniqueId from 'lodash/uniqueId'
import sanitizeHtml from 'sanitize-html'
import ServerSelect from '@/components/ServerSelect.vue'
import { ansiToHtml, stripAnsiCodes } from '@/utils/ansi.util'
import MessageList from '@/components/MessageList.vue'
import { debug, error } from '@/utils/log'

const CONNECTED = 0
const DISCONNECTED = 1

export default {
  components: {
    ServerSelect,
    MessageList
  },
  props: {
    theme: {
      type: String,
      default: 'dark'
    }
  },
  data() {
    return {
      userState: DISCONNECTED,
      prompt: '',
      history: []
    }
  },
  mounted() {
    const socket = this.getSocket()

    // Setup socket event handlers
    socket.onopen = () => {
      debug('socket opened')
    }
    socket.onmessage = ({ data }) => {
      const { type, data: payload } = JSON.parse(data)

      debug('received: ', type, payload)
      switch (type) {
        case 'connected':
          this.handleConnect()
          break
        case 'telnet':
          this.write({
            source: 'server',
            content: payload.message
          })
          break
      }
    }
    socket.onclose = () => {}
    socket.onerror = (err) => {
      error(err)
    }
    this.socket = socket
    this.messages = new Map()
  },
  computed: {
    messages() {
      // Process message history for display.
      return this.history.map(({ id, source, content }) => {
        // Strip unsafe content since we're rendering the raw text with v-html.
        content = sanitizeHtml(content)

        if (source === 'server') {
          return {
            id,
            source,
            content: this.processTelnetMessage(content)
          }
        }

        return { id, source, content }
      })
    },
    isConnected() {
      return this.userState === CONNECTED
    }
  },
  methods: {
    writeCommand(cmd) {
      this.writeln({
        source: 'user',
        content: cmd
      })
    },
    // Append content to the current line.
    write({ source, content }) {
      // Continue appending server messages until another message type is receieved.
      if (this.history.length && source === 'server') {
        const curIdx = this.history.length - 1
        const { source: curSource, content: curContent } = this.history[curIdx]

        debug('current', curContent)
        debug('new', curContent + content)

        if (curSource === 'server') {
          this.history[curIdx].content = curContent + content
          return
        }
      }

      this.writeln({ source, content })
    },
    // Write content on a new line
    writeln({ source, content }) {
      // TODO: Consider how much history to maintain. At a certain size we should start dropping
      // older entries to save memory.
      this.history.push({
        id: uniqueId('message_'),
        source,
        content
      })
    },
    processTelnetMessage(message) {
      // TODO: Add support for user settings.
      const colorsEnabled = true

      // Strip carriage returns since we're rendering in a browser instead of a terminal.
      message = message.replaceAll('\r', '')
      // Strip ANSI escape sequences or convert them to HTML based on user preference.
      message = colorsEnabled ? ansiToHtml(message, this.theme) : stripAnsiCodes(message)

      return message
    },
    send(data) {
      this.socket.send(JSON.stringify(data))
    },
    sendConnect(server) {
      this.send({
        type: 'connect',
        data: {
          server
        }
      })
    },
    sendCommand(command) {
      this.send({
        type: 'command',
        data: {
          command
        }
      })
    },
    handleSubmit({ server, port }) {
      this.sendConnect(`${server}:${port}`)
    },
    async handleConnect() {
      this.userState = CONNECTED
      await nextTick()
      this.$refs.prompt.focus()
    },
    handleEnter() {
      const command = this.prompt
      this.$refs.prompt.select()
      this.writeCommand(command)

      switch (this.userState) {
        case DISCONNECTED:
          return this.sendConnect(command)
        case CONNECTED:
          return this.sendCommand(command)
      }
    },
    getSocket() {
      const { protocol, host } = location
      const wsProtocol = protocol === 'https:' ? 'wss' : 'ws'

      return new WebSocket(`${wsProtocol}://${host}/ws`)
    }
  }
}
</script>

<style lang="less" scoped>
.terminal-view {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &__messages,
  &__controls {
    display: flex;
    align-items: center;

    & > * {
      width: 100%;
      text-wrap: wrap;
      overflow-wrap: break-word;
    }
  }

  &__init {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 var(--padding);
    flex-grow: 1;
  }

  &__messages {
    flex-grow: 1;
    flex-direction: column-reverse;
    overflow: hidden;
    padding-top: var(--padding);
  }

  &__controls {
    flex-direction: column;
    margin-top: 10px;
    padding-bottom: var(--padding);

    input {
      border: solid 2px var(--border-color);
      border-radius: 4px;
      background: var(--bg-color);
      width: 100%;
      padding: 4px;
      max-width: 750px;
      margin: 0;

      &:focus,
      &:focus-visible {
        border-color: var(--focus-border-color);
        // TODO: This may not follow accessibility patterns to take another look at this.
        outline: none;
      }

      &:active {
        border-color: var(--active-border-color);
      }
    }
  }
}
</style>
