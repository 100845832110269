<template>
  <div class="site-menu" :class="{ active }">
    <ul>
      <li><RouterLink to="/">Console</RouterLink></li>
      <li><RouterLink to="/macros">Macros</RouterLink></li>
      <li><RouterLink to="/about">About</RouterLink></li>
    </ul>
    <div class="site-settings">
      {{ themeName }}
      <ToggleInput :value="theme" @change="$emit('change-theme', $event)" />
    </div>
  </div>
</template>

<script>
import capitalize from 'lodash/capitalize'
import ToggleInput from '@/components/ToggleInput.vue'

export default {
  components: {
    ToggleInput
  },
  emits: ['change-theme'],
  props: {
    active: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: 'dark'
    }
  },
  computed: {
    themeName() {
      return capitalize(this.theme)
    }
  }
}
</script>

<style lang="less" scoped>
.site-menu {
  position: fixed;
  left: var(--padding);
  top: var(--padding);
  opacity: 0.25;
  transition: opacity 0.3s ease;
  z-index: 1;

  &.active {
    opacity: 1;
  }

  ul {
    padding: 0;

    li {
      list-style: none;
      margin-bottom: 10px;

      a {
        text-decoration: underline;

        &:not(.router-link-active) {
          color: var(--text-color);
          text-decoration: none;
        }
      }
    }
  }
}

.site-settings {
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 20px;
}
</style>
