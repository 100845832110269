<template>
  <label class="switch">
    <input
      type="checkbox"
      v-model="localValue"
      true-value="light"
      false-value="dark"
      @change="handle"
    />
    <span class="slider round"></span>
  </label>
</template>

<script>
export default {
  emits: ['change', 'input'],
  props: {
    value: {
      type: String,
      required: true
    }
  },
  watch: {
    value(newValue) {
      this.localValue = newValue
    }
  },
  data() {
    return {
      localValue: this.value
    }
  },
  methods: {
    handle() {
      this.$emit('change', this.localValue)
    }
  }
}
</script>

<style lang="less" scoped>
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: #121212;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px #121212;
    }

    &:checked + .slider:before {
      -webkit-transform: translateX(11px);
      -ms-transform: translateX(11px);
      transform: translateX(11px);
    }
  }
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;

  &::before {
    position: absolute;
    content: '';
    height: 13px;
    width: 13px;
    left: 4px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
}
</style>
