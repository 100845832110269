import Convert from 'ansi-to-html'

const converters = {
  light: new Convert({
    colors: [
      '#000000', // Black
      '#e60000', // Red
      '#00a300', // Green
      '#d7a300', // Yellow
      '#0050d7', // Blue
      '#d700d7', // Magenta
      '#00d7d7', // Cyan
      '#333333', // White (changed to dark gray for contrast)
      '#4d4d4d', // Bright Black (Gray)
      '#ff4d4d', // Bright Red (Light Red)
      '#4dff4d', // Bright Green (Light Green)
      '#ffff4d', // Bright Yellow (Light Yellow)
      '#4d4dff', // Bright Blue (Light Blue)
      '#ff4dff', // Bright Magenta (Light Magenta)
      '#4dffff', // Bright Cyan (Light Cyan)
      '#e0e0e0' // Bright White
    ]
  }),
  dark: new Convert({
    colors: [
      '#121212', // Black
      '#ff5555', // Red
      '#50fa7b', // Green
      '#f1fa8c', // Yellow
      '#bd93f9', // Blue
      '#ff79c6', // Magenta
      '#8be9fd', // Cyan
      '#f8f8f2', // White
      '#6272a4', // Bright Black (Gray)
      '#ff6e6e', // Bright Red (Light Red)
      '#69ff94', // Bright Green (Light Green)
      '#ffffa5', // Bright Yellow (Light Yellow)
      '#d6acff', // Bright Blue (Light Blue)
      '#ff92df', // Bright Magenta (Light Magenta)
      '#a4ffff', // Bright Cyan (Light Cyan)
      '#e0e0e0' // Bright White
    ]
  })
}
// Regular expression to match ANSI escape codes
const ansiRegex = /\x1b\[[0-9;]*m/g // eslint-disable-line no-control-regex

export function stripAnsiCodes(text) {
  return text.replace(ansiRegex, '')
}

export function ansiToHtml(content, theme = 'dark') {
  const ansi = converters[theme] || converters.dark

  return ansi.toHtml(content)
}
