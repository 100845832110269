<template>
  <div class="message-list">
    <DynamicScroller
      ref="scroller"
      :items="messages"
      :min-item-size="18"
      emit-update
      class="scroller"
      @update="onUpdate"
      @scroll-start="onScrollStart"
      @scroll-end="onScrollEnd"
    >
      <template #default="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :active="active"
          :size-dependencies="[item.content]"
          :data-index="index"
          :data-active="active"
          class="scroller-item"
        >
          <span class="message" :data-source="item.source" v-html="item.content" />
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>
    <div v-if="isScrolledUp" class="scroll-to-bottom" @click="scrollToBottom">
      <app-icon icon="arrow-down-circle" />
    </div>
  </div>
</template>

<script>
import AppIcon from './AppIcon.vue'

export default {
  name: 'MessageList',
  components: {
    AppIcon
  },
  props: {
    messages: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isScrolledUp: false
    }
  },
  watch: {
    messages() {
      if (!this.isScrolledUp) {
        this.scrollToBottom()
      }
    }
  },
  methods: {
    onUpdate(startIndex, endIndex, visibleStartIndex, visibleEndIndex) {
      this.isScrolledUp = visibleEndIndex < this.messages.length
    },
    scrollToBottom() {
      this.$refs.scroller.scrollToBottom()
    }
  }
}
</script>

<style lang="less" scoped>
.message-list {
  position: relative;

  .scroller {
    // TODO: Try to think of a solution for larger "welcome" prompts coming from servers when the user
    // is on mobile. Maybe the first message is font-size 8px and the rest use --font-size-sm
    font-size: var(--font-size-sm);
    white-space: pre;
    display: flex;
    flex-direction: column;
    align-items: center;

    // Set the max-width on the item wrapper so we can keep the scrollbar all the way to the right
    // of the page.
    :deep(.vue-recycle-scroller__item-wrapper) {
      max-width: 750px;
    }

    .message {
      &[data-source='user'] {
        color: var(--user-message-color);
        display: block;
      }
    }
  }

  .scroll-to-bottom {
    position: absolute;
    width: fit-content;
    bottom: -2px;
    padding: 2px;
    line-height: 0;
    color: var(--input-text-secondary-color);
    cursor: pointer;
    transition:
      color 0.3s ease,
      background-color 0.3s ease;
    border-radius: 50%;
    background-color: var(--bg-color);

    &:hover {
      color: var(--input-text-color);
    }

    svg {
      stroke-width: 1.5;
    }
  }
}
</style>
