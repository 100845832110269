<template>
  <div class="server-select">
    <h2>Where are you adventuring today?</h2>
    <div class="server-select__input">
      <app-icon class="icon" :height="18" :width="18" icon="chevron-down" />
      <select v-model="server">
        <option value="">Choose a server to begin</option>
        <option v-for="{ id, name } in serverOptions" :key="id" :value="id">
          {{ name }}
        </option>
      </select>
    </div>
    <div v-if="server" class="server-select__server">
      <input-text
        v-if="server === 'custom'"
        ref="customServer"
        v-model="serverAddr"
        placeholder="example.com:1234"
        :error="error"
      />
      <span v-else> {{ currentSelection.server }}:{{ currentSelection.port }} </span>
    </div>
    <div v-if="server" class="server-select__actions">
      <button @click="handleSubmit(currentSelection)">Summon Connection</button>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue'
import { useStorage } from '@vueuse/core'
import AppIcon from '@/components/AppIcon.vue'
import InputText from '@/components/InputText.vue'
import ServerList from '@/data/mud-servers.json'

const VALID_DOMAIN = /^(?:(?!-)[a-zA-Z0-9-]{1,63}(?<!-)\.)+(?:[a-zA-Z]{2,})$/
const VALID_IP =
  /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/
const isValidAddr = (addr) => VALID_DOMAIN.test(addr) || VALID_IP.test(addr)
const isValidPort = (port) => {
  const portNumber = parseInt(port, 10)
  return Number.isInteger(portNumber) && portNumber > 0 && portNumber <= 65535
}

export default {
  components: {
    AppIcon,
    InputText
  },
  emits: ['submit'],
  data() {
    return {
      server: '',
      serverAddr: '',
      error: ''
    }
  },
  setup() {
    const lastUsedServer = useStorage('mud-lastServer', { id: '' })

    return { lastUsedServer }
  },
  watch: {
    async server(newValue) {
      if (newValue === 'custom') {
        await nextTick()
        this.$refs.customServer.focus()
      }
    }
  },
  mounted() {
    const { id, server, port } = this.lastUsedServer

    if (id === 'custom') {
      this.serverAddr = `${server}:${port}`
    }
    this.server = id
  },
  computed: {
    currentSelection() {
      return this.serverOptions.find(({ id }) => id === this.server)
    },
    serverOptions() {
      return [
        ...ServerList,
        {
          id: 'custom',
          name: 'Custom'
        }
      ]
    }
  },
  methods: {
    handleSubmit(server) {
      if (server.id === 'custom') {
        let [addr, port] = this.serverAddr.split(':')
        port = port || '23'

        if (!(isValidAddr(addr) && isValidPort(port))) {
          this.error = 'Invalid server address'
          console.error(this.error, addr, port)
          return
        }

        server = {
          ...server,
          name: addr,
          server: addr,
          port
        }
      }

      this.lastUsedServer = server
      this.$emit('submit', server)
    }
  }
}
</script>

<style lang="less" scoped>
.server-select {
  &__input {
    position: relative;

    .icon {
      position: absolute;
      top: 6px;
    }

    select {
      border: solid 2px var(--bg-color);
      border-radius: 4px;
      width: 100%;
      padding: 4px 4px 4px 20px;
      color: var(--text-color);
      outline: none;
      appearance: none;

      &:focus,
      &:focus-visible {
        border-color: var(--active-border-color);
      }
    }
  }

  &__server {
    font-size: 14px;
    color: var(--input-text-secondary-color);

    input {
      border: solid 2px var(--border-color);
      border-radius: 4px;
      background: var(--bg-color);
      width: 100%;
      padding: 4px;

      &:focus,
      &:focus-visible {
        border-color: var(--focus-border-color);
        outline: none;
      }

      &:active {
        border-color: var(--active-border-color);
      }
    }
  }

  &__actions {
    margin-top: var(--padding);

    button {
      text-transform: uppercase;
      width: 100%;
      padding: 20px;
      font-weight: 600;
    }
  }
}
</style>
