<template>
  <div class="app-view">
    <TheNav :active="!hasMouseInTerminal" :theme="theme" @change-theme="setTheme" />
    <div class="site-content">
      <RouterView class="page-view" #default="{ Component }">
        <KeepAlive>
          <Component
            :is="Component"
            :theme="theme"
            @mouseleave="hasMouseInTerminal = false"
            @mouseenter="hasMouseInTerminal = true"
          />
        </KeepAlive>
      </RouterView>
    </div>
  </div>
</template>
<script>
import TheNav from '@/components/TheNav.vue'

export default {
  components: {
    TheNav
  },
  data() {
    return {
      hasMouseInTerminal: false,
      theme: ''
    }
  },
  created() {
    if (window.matchMedia) {
      const query = window.matchMedia('(prefers-color-scheme: dark)')
      const handleQuery = (ev) => this.setTheme(ev.matches ? 'dark' : 'light')

      handleQuery(query)
      query.addEventListener('change', handleQuery)
    } else {
      this.setTheme('light')
    }
  },
  methods: {
    setTheme(theme) {
      document.body.dataset.theme = theme
      this.theme = theme
    }
  }
}
</script>

<style scoped lang="less">
.app-view {
  width: 100%;
  height: 100%;

  .site-content {
    height: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
